import { CFRABadge, CustomCheckbox, ETFMenu, Layout } from '@cfra-nextgen-frontend/shared';
import { ReactComponent as IconChecked } from '@cfra-nextgen-frontend/shared/src/assets/icons/iconchecked.svg';
import { ReactComponent as IconUnchecked } from '@cfra-nextgen-frontend/shared/src/assets/icons/iconunchecked.svg';
import SortIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/sort.svg';
import { noResultText } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { DividerStyle2 } from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import { CommonButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/CommonButton';
import { MenuItemProps } from '@cfra-nextgen-frontend/shared/src/components/ETFMenu';
import { LISTBOX_PADDING } from '@cfra-nextgen-frontend/shared/src/components/Form/FormVirtualizeAutocomplete';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { ItemVariant7 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant7';
import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { ResearchDescriptionText } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import {
    TypographyStyle2,
    TypographyStyle24,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { LinkGeneratorModes } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { ApiNames, fontWeights, RequestTypes, ResearchTypeId } from '@cfra-nextgen-frontend/shared/src/utils';
import { getSnippedText } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Box, createTheme, Stack, SxProps, ThemeProvider } from '@mui/material';
import { BadgeMenuItemStyle, BadgeThemeWithMenu, companyHeadlineItemTheme } from 'components/themes/theme';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { PageNames, PageNamesToRoutePaths, ResearchReportTypes } from 'utils/enums';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';
import { getAvailableResearchTypesForCompany, getCompanyHeadlines, getResearchAggregates } from '../api/company';
import { CompanyParams, MenuItemState } from '../types/company';

const getRowComponent = ({ rowData }: { rowData: any }) => {
    const researchId = rowData.research_report._id;
    const publishDate = moment(rowData.research_report.publish_timestamp).format('MMMM DD, YYYY');
    const title = rowData.research_report.title;
    const summary = getSnippedText(rowData.research_report.teaser || '', 100);
    const reportType = rowData.research_report.research_type_name;
    const reportTypeID = rowData.research_report.research_type_id;
    const hasModalFeature = reportTypeID === ResearchReportTypes.SpecialInterest;

    const linkParams = researchLinkGetterParams[0];
    linkParams.mode = hasModalFeature ? LinkGeneratorModes.ExtractLink : LinkGeneratorModes.OpenInNewTab;

    return (
        <ItemVariant7<ScreenerResearchData>
            handleOuterSetRequestParamsProps={(setRequestParamsProps) =>
                setRequestParamsProps(getRequestParamsPropsVariant1(researchId))
            }
            firstRowItems={[reportType, publishDate]}
            secondRowText={title}
            thirdRowText={summary}
            useLinkGetterParams={researchLinkGetterParams}
            itemTheme={companyHeadlineItemTheme}
            hasModalFeature={hasModalFeature}
        />
    );
};

const getIcon = (icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>) => (
    <CustomSvgIcon
        component={icon}
        viewBox='0 0 18 18'
        sx={{
            width: '18px',
            height: '18px',
        }}
    />
);

const UncheckedIcon = getIcon(IconUnchecked);
const CheckedIcon = getIcon(IconChecked);

export const getMenuItems = (reports: Array<MenuItemState>, onChange: any): Array<MenuItemProps> => {
    return reports.map((report) => {
        return {
            itemName: (
                <>
                    <CustomCheckbox
                        icon={UncheckedIcon}
                        checkedIcon={CheckedIcon}
                        name={report.name}
                        checked={report.isChecked}
                        sx={{ marginRight: '11px' }}
                    />
                    {report.name + ' '}
                    <Box sx={{ color: '#007AB9', fontWeight: 500, paddingLeft: '10px' }}>{report.count}</Box>
                </>
            ),
            callback: (e) => onChange(e, report),
        };
    });
};

export const getFilterComponent = (
    menuItems: Array<MenuItemProps>,
    checkedCount: number,
    maxDisplaySize = 7,
    label: any = '',
) => {
    const itemCount = menuItems.length;
    const itemSize = 42;
    const height = (itemCount >= maxDisplaySize ? maxDisplaySize : itemCount) * itemSize + 2 * LISTBOX_PADDING;
    return (
        menuItems.length > 0 && (
            <ThemeProvider theme={BadgeThemeWithMenu} key='getfilterComponent'>
                <ETFMenu.ETFMenu
                    key='companyHeadlinesFilter'
                    menuStyles={{ minWidth: '190px', flexWrap: 'wrap', height }}
                    menuItemStyles={{
                        ...BadgeMenuItemStyle,
                    }}
                    keepOpen={true}
                    paperProps={{
                        borderTop: '11px solid white',
                        borderBottom: '11px solid white',
                        ...scrollbarThemeV3,
                    }}
                    menuItems={menuItems}
                    addBorderBetweenItems={false}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                    <Box display='flex' alignItems='center' gap='6px'>
                        <CFRABadge badgeContent={checkedCount} color='primary' sx={{ cursor: 'pointer' }}>
                            <Box component='img' src={SortIcon} height='16px' />
                        </CFRABadge>
                        {label}
                    </Box>
                </ETFMenu.ETFMenu>
            </ThemeProvider>
        )
    );
};

const containerSx: SxProps = {
    backgroundColor: '#FFFF',
    borderRadius: '10px',
};

const headerSx: SxProps = {
    p: '24px 28px 10px 28px',
    alignItems: 'center',
};

const scrollContainerSx: SxProps = {
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden auto',
    width: '100%',
    minHeight: '300px',
    ...scrollbarThemeV3,
};

const loadingCntrSx: SxProps = { m: '48px 0', width: '100%', textAlign: 'center' };

export type CompanyHeadlinesProps = {
    queryParams: CompanyParams;
    isLoading: boolean;
    gics_code: string;
};

const filterListPanelSize: number = 7;

export function CompanyHeadlines({ queryParams, isLoading, gics_code }: CompanyHeadlinesProps) {
    const navigate = useNavigate();
    const [reports, setReports] = useState<Array<MenuItemState>>([]);
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    const availableResearchTypes = sendSingleRequest?.(getAvailableResearchTypesForCompany(queryParams, gics_code), {
        requestType: RequestTypes.POST,
        path: 'research/screener-filters',
        queryKeyFirstElement: 'getAvailableResearchTypes',
        apiName: ApiNames.Research,
    });

    const reportsScreenerQry = sendSingleRequest?.(
        {
            ...getCompanyHeadlines(
                queryParams,
                reports.filter((report) => report.isChecked).map((report) => report.name),
                gics_code,
            ),
            size: 500,
        },
        {
            queryKeyFirstElement: 'getCompanyHeadlines',
            apiName: ApiNames.Research,
            requestType: RequestTypes.POST,
            path: 'research/screener',
        },
    ) as UseQueryResult<ScreenerResearchData>;

    const latestReportList = useMemo(() => {
        const data: any[] = [];
        let foundLatestIndustryReport = false;
        if (!reportsScreenerQry?.isLoading && reportsScreenerQry?.data?.results?.research) {
            reportsScreenerQry?.data?.results?.research.forEach((report, idx) => {
                if (report?.research_report?.research_type_id !== ResearchTypeId.FrsIndustryReport) {
                    data.push(report);
                } else if (!foundLatestIndustryReport) {
                    foundLatestIndustryReport = true;
                    data.push(report);
                }
            });
        }
        return data;
    }, [reportsScreenerQry?.isLoading, reportsScreenerQry?.data?.results?.research]);

    const onChange = (_e: any, _report: { name: string }) => {
        const newReportState = reports.map((report) => {
            if (report.name === _report.name) {
                report.isChecked = !report.isChecked;
            }
            return report;
        });
        setReports(newReportState);
    };

    useEffect(() => {
        if (availableResearchTypes?.isSuccess) {
            const allReports = getResearchAggregates(availableResearchTypes?.data);
            setReports(
                allReports.map((report) => {
                    let count = report.count;
                    if (parseInt(report.key) === ResearchTypeId.FrsIndustryReport && count > 1) {
                        count = 1;
                    }
                    return { key: report.key, name: report.value, isChecked: false, count };
                }),
            );
        }
    }, [availableResearchTypes?.isSuccess, availableResearchTypes?.data]);

    const noReports = useMemo(() => {
        return !isLoading && !reportsScreenerQry?.isLoading && latestReportList.length === 0;
    }, [isLoading, latestReportList.length, reportsScreenerQry?.isLoading]);

    const handleOnViewAllClick = useCallback((url: string) => navigate(url), [navigate]);

    return (
        <ThemeProvider theme={createTheme()}>
            <Stack sx={containerSx}>
                <Box sx={headerSx}>
                    <Grid container flexWrap='wrap' alignItems='center' gap='10px'>
                        <Grid item xs minWidth='200px'>
                            <TypographyStyle2 fontWeight={fontWeights.Medium} lineHeight='28px'>
                                Latest Headlines
                            </TypographyStyle2>
                        </Grid>

                        <Grid item>
                            <Stack direction='row' justifyContent='space-between' gap='20px'>
                                {[
                                    getFilterComponent(
                                        getMenuItems(reports, onChange),
                                        reports.filter((report) => report.isChecked).length,
                                        filterListPanelSize,
                                        <TypographyStyle24 sx={{ mb: '-4px', cursor: 'pointer', lineHeight: '18px' }}>
                                            Filter
                                        </TypographyStyle24>,
                                    ),
                                    <CommonButton
                                        outlined={true}
                                        theme={createTheme()}
                                        sx={{ p: '8px 14px' }}
                                        content={
                                            <TypographyStyle24 sx={{ textTransform: 'none', lineHeight: '18px' }}>
                                                View All
                                            </TypographyStyle24>
                                        }
                                        onClickCallback={() =>
                                            handleOnViewAllClick(PageNamesToRoutePaths[PageNames.ResearchHub])
                                        }
                                    />,
                                ]}
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                <DividerStyle2 sx={{ mx: '28px' }} />
                <Box p='28px' pt='10px'>
                    <Box
                        sx={{
                            ...scrollContainerSx,
                            maxHeight: queryParams.enableQuery
                                ? { lg: '520px', md: '585px', sm: '430px', xs: '480px' }
                                : '180px',
                        }}>
                        {(isLoading || reportsScreenerQry?.isLoading) && (
                            <Box sx={loadingCntrSx}>
                                <Layout.Skeleton height='10px' />
                            </Box>
                        )}
                        {noReports && (
                            <Box sx={loadingCntrSx}>
                                <ResearchDescriptionText>{noResultText}</ResearchDescriptionText>
                            </Box>
                        )}
                        {latestReportList.map((research: any, idx: number) => {
                            return getRowComponent({ rowData: research });
                        })}
                    </Box>
                </Box>
            </Stack>
        </ThemeProvider>
    );
}
