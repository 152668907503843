import DividerStyle1 from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import ETFModal, { Props } from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { Box, CssBaseline, SxProps, Theme, ThemeProvider, Typography } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import React, { Fragment, useCallback, useRef, useState } from 'react';

export type ItemVariant9BaseProps = {
    firstRowItems: Array<string | undefined>;
    innerRef?: React.Ref<HTMLButtonElement>;
    secondRowText: string;
    thirdRowText?: string;
    itemTheme: Theme;
    hasModalFeature?: boolean;
};

type ItemVariant9Props = ItemVariant9BaseProps & {
    onClick: () => void;
    extractedLink?: string;
    children?: React.ReactNode;
    modalProps?: Partial<Props>;
    componentRightSlot?: React.ReactNode;
    buttonSx?: SxProps;
};

const textContainerSx: SxProps = {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
};

const summarySx: SxProps = {
    ...textContainerSx,
    pt: '4px',
    '@media (max-width: 500px)': {
        display: 'none',
    },
};

export function ItemVariant9({
    firstRowItems,
    innerRef,
    secondRowText,
    thirdRowText,
    itemTheme,
    hasModalFeature,
    onClick,
    extractedLink,
    children,
    modalProps,
    componentRightSlot,
    buttonSx,
}: ItemVariant9Props) {
    const [modalOpen, setModalOpen] = useState(false);

    const modalRef = useRef(null);

    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
    }, []);

    const handleOpenModal = useCallback(() => {
        setModalOpen(true);
    }, []);

    const onClickLocal = useCallback(() => {
        onClick();
        if (hasModalFeature) {
            handleOpenModal();
        }
    }, [hasModalFeature, handleOpenModal, onClick]);

    return (
        <>
            <ThemeProvider theme={itemTheme}>
                <CssBaseline />
                <ButtonBase ref={innerRef} onClick={onClickLocal} sx={buttonSx}>
                    {/* First Row */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            paddingBottom: '3px',
                        }}>
                        {firstRowItems.map(
                            (item, index) =>
                                item && (
                                    <Fragment key={item}>
                                        <Typography variant={index === 0 ? 'researchType' : 'caption'} key={item}>
                                            {item}
                                        </Typography>
                                        {index < firstRowItems.length - 1 && <DividerStyle1 />}
                                    </Fragment>
                                ),
                        )}
                    </Box>
                    {/* Second Row */}
                    <Box sx={textContainerSx}>
                        <Typography variant='headlineTitle'>{secondRowText}</Typography>
                    </Box>
                    {thirdRowText && (
                        <Box sx={summarySx}>
                            <Typography variant='summary'>{thirdRowText}</Typography>
                        </Box>
                    )}
                    {componentRightSlot}
                </ButtonBase>
            </ThemeProvider>
            {hasModalFeature && (extractedLink || (children && modalOpen)) && (
                <ETFModal
                    date={firstRowItems[0]}
                    getOpenComponent={() => <></>}
                    title={secondRowText}
                    modalBoxStyles={{
                        display: 'block',
                        maxWidth: '1400px',
                        maxHeight: 'none',
                        overflowY: 'hidden',
                    }}
                    descriptionStyle={{
                        boxShadow: 'none',
                    }}
                    titleStyle={{
                        boxShadow: 'none',
                        fontSize: '18px',
                        color: '#002B5A',
                        lineHeight: '1.56em',
                    }}
                    dateStyle={{
                        boxShadow: 'none',
                        color: '#A09F9F',
                        fontSize: '12px',
                        paddingBottom: '0',
                        lineHeight: '1.5em',
                    }}
                    zIndex={5000}
                    externalOpenModal={modalOpen}
                    keepContentOnClose={false}
                    callbackOnClose={handleCloseModal}
                    ref={modalRef}
                    htmlContentUrl={extractedLink}
                    {...modalProps}>
                    {children}
                </ETFModal>
            )}
        </>
    );
}
