export function convertToUrlFormat(pageTitle: string) {
    return pageTitle.toLocaleLowerCase().replaceAll(' ', '-');
}

export function replaceAllDotsWithTabs(value: string) {
    return value.replaceAll('.', '\t');
}

export function replaceAllTabsWithDots(value: string) {
    return value.replaceAll('\t', '.');
}

export function getDividerString(dividerName: string, wrapperString: string = '___') {
    return `${wrapperString}${dividerName}${wrapperString}`;
}

export function quote(text: string): string {
    return `"${text}"`;
}

export function joinWithHtmlBr(errors: Array<string>): string {
    return errors.join('<br>');
}

export function getSnippedText(text: string, maxLength: number) {
    let snippedText = '';
    let charCount = 0;
    let maxReached = false;

    if (!text) {
        return '';
    }

    for (let i = 0; i < text.length; i++) {
        if (maxReached && text[i] === ' ') {
            break;
        }

        snippedText += text[i];
        if (text[i] !== ' ') {
            charCount += 1;
        }

        if (charCount === maxLength) {
            maxReached = true;
        }
    }

    if (snippedText.length >= charCount) {
        snippedText += ' ...';
    }

    return snippedText;
}